/* body {
  overflow-x: hidden;
} */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.container {
  max-width: max-content;
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
}
.secondary-image {
  flex-direction: row-reverse;
}
.main-image,
.secondary-image {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.img-main,
.secondary-image img {
  width: 600px;
  border-radius: 5px;
  margin-right: 1px;
}

.secondary-image img {
}
.img-element {
  margin-right: 0;
  height: 600px;
}
.img-main {
  margin-right: 2rem;
}

.info {
  flex: 1;
  padding: 20px;
  text-align: center;
  font-size: 1.3rem;
  box-sizing: border-box;
}

.h2-main,
.p-main {
  text-align: initial;
  width: 900px;
}

/* .info h2 {
} */

.main-image {
  margin-right: auto;
}

.secondary-image.highlighted {
  background-color: #e9ebe8;
}

/*-------------------------------------------BREAKPOINTS-----------------------------------------*/

/*For large devices*/
@media screen and (max-width: 1400px) {
  .container {
    width: fit-content;
  }
  .img-element {
    height: 500px;
  }
  .info {
    font-size: var(--h2-font-size);
  }
  .h2-main,
  .p-main {
    text-align: initial;
    width: 540px;
  }

  .img-main,
  .secondary-image img {
    width: 630px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: fit-content;
  }
  .img-element {
    height: 500px;
  }
  .info {
    font-size: var(--h2-font-size);
  }
  .h2-main,
  .p-main {
    text-align: initial;
    width: 500px;
  }

  .img-main {
    width: 430px;
  }
  .secondary-image img {
    width: 500px;
  }
}
@media screen and (max-width: 992px) {
  .secondary-image.highlighted {
    background: none;
  }
  .info {
    font-size: var(--h3-font-size);
    margin-top: 1rem;
    margin-bottom: 1.4rem;
  }
  .h2-main,
  .p-main {
    text-align: initial;
    width: 400px;
  }

  .img-element {
  }
  .img-main,
  .secondary-image img {
    width: 412px;
    border-radius: 20px;
    margin-right: 6px;
  }
}

@media screen and (max-width: 884px) {
  .info {
    font-size: var(--h3-font-size);
  }
  .p-main {
    width: fit-content;
  }
  .img-element {
    height: 330px;
  }
  .img-main,
  .secondary-image img {
    width: 375px;
    border-radius: 20px;
    margin-right: 6px;
  }
  /* .h2-main,
  .p-main {
    text-align: initial;
    width: 540px;
  } */
}

@media screen and (max-width: 884px) {
  .h2-main,
  .p-main {
    text-align: initial;
    width: 400px;
  }
}

@media screen and (max-width: 800px) {
  .h2-main,
  .p-main {
    text-align: initial;
    width: 350px;
  }
}

/*For medium devices*/
@media screen and (max-width: 768px) {
  .main-image,
  .secondary-image {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
  .h2-main,
  .p-main {
    text-align: center;
    width: 550px;
  }
  .info {
    font-size: var(--h3-font-size);
  }

  .img-main {
    width: 560px;
    margin-left: 0.2rem;
  }
  .img-element {
    display: none;
  }

  .secondary-image img {
    width: 600px;
    align-items: center;
  }
  .secondary-image {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 576px) {
  .main-image,
  .secondary-image {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
  .p-main {
    text-align: center;
  }

  .h2-main {
    width: auto;
    text-align: center;
  }
  /* .p-main {
    width: fit-content;
    margin-left: -1rem;
    padding: 0;
  } */
  .info {
    font-size: 0.8rem;
  }
  .img-main {
    width: 100%;
    margin-left: 1.4rem;
  }
  .img-element {
    display: none;
  }

  .secondary-image img {
    width: 100%;
    align-items: center;
    margin-left: 1.2rem;
  }
  .secondary-image {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 430px) {
  .main-image,
  .secondary-image {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
  .h2-main,
  .p-main {
    margin-left: -0.1rem;
    width: 350px;
    padding: 0;
  }
  .info {
    font-size: 1rem;
    width: 380px;
    margin-top: 2rem;
  }
  .img-main {
    width: 380px;

    margin-left: 5px;
  }
  .img-element {
    display: none;
  }

  .secondary-image img {
    width: 390px;
    align-items: center;
    margin-left: 0.1rem;
  }
  .secondary-image {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 414px) {
  /* .main-image,
  .secondary-image {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
  .p-main {
    margin-left: -1rem;
    padding: 0;
  } */
  /* .info {
    font-size: 0.8rem;
  }
  .img-main {
    width: 350px;
    margin-left: 0;
  }
  .img-element {
    display: none;
  }

  .secondary-image img {
    width: 320px;
    align-items: center;
    margin-left: 0.1rem;
  } 
  */

  .secondary-image {
    width: 100%;
    margin-left: 0;
  }

  .secondary-image p {
    margin-left: -1rem;
  }
  .img-main {
    width: 350px;
    margin-left: -0.7rem;
  }
  .h2-main {
    margin-left: -0.4rem;
  }
  .p-main {
    margin-left: -0.7rem;
  }
  .secondary-image img {
    width: 350px;
    align-items: center;
    margin-left: -0.7rem;
  }
}

@media screen and (max-width: 393px) {
  /* body {
    overflow-x: hidden;
  } */
  .img-main {
    width: 340px;
    margin-left: -1rem;
  }
  .p-main {
    margin-left: -1rem;
    width: 340px;
  }

  .h2-main {
    margin-left: -1.8rem;
  }
  .secondary-image img {
    width: 340px;
    align-items: center;
    margin-left: -0.7rem;
  }
  .secondary-image p {
    align-items: center;
    margin-left: -1.3rem;
  }

  .secondary-image h2 {
    align-items: center;
    margin-left: -1.7rem;
  }
}
@media screen and (max-width: 390px) {
  .info {
    font-size: 0.9rem;
    width: 360px;
    margin-top: 2rem;
    margin-left: -0.2rem;
  }
  .p-main {
    margin-left: -0.9rem;
  }

  .secondary-image p {
    margin-left: -1rem;
  }
  .secondary-image img {
    width: 335px;
    align-items: center;
    margin-left: -0.5rem;
  }

  .img-main {
    width: 320px;
    margin-left: -0.6rem;
  }
}

@media screen and (max-width: 375px) {
  .img-main {
    width: 310px;
    margin-left: -0.2rem;
  }
  .p-main {
    margin-left: -0.3rem;

    width: 320px;
  }
  .secondary-image p {
    margin-left: 1.5rem;
  }
  .h2-main {
    margin-left: -0.1rem;
  }
  .info h2 {
    margin-left: -1rem;
    width: 350px;
  }
  .info {
    margin-left: -1rem;
    width: 350px;
    font-size: var(--small-font-size);
  }

  .secondary-image img {
    width: 280px;
    align-items: center;
    margin-left: -0.1rem;
  }
}

@media screen and (max-width: 360px) {
  .img-main {
    width: 310px;
    margin-left: -1rem;
  }
  .p-main {
    margin-left: -0.3rem;

    width: 320px;
  }
  .secondary-image p {
    margin-left: 0;
  }
  .h2-main {
    margin-left: -1rem;
  }
  .info {
    margin-left: -1rem;
    width: 350px;
    font-size: var(--small-font-size);
  }

  .secondary-image h2 {
    align-items: center;
    margin-left: -0.9rem;
  }
  .secondary-image p {
    align-items: center;
    margin-left: -0.9rem;
  }
  .secondary-image img {
    width: 280px;
    align-items: center;
    margin-left: -0.9rem;
  }
}

@media screen and (max-width: 350px) {
  body {
    overflow-x: hidden;
  }
  .img-main {
    width: 280px;
    margin-left: -1.7rem;
  }
  .p-main {
    width: 297px;
    margin-left: 0.5rem;
    align-items: center;
  }
  .info {
    margin-left: -1.7rem;
  }
  .info h2 {
    margin-left: -1rem;
    width: auto;
  }
  .h2-main {
    align-items: center;
  }

  .secondary-image img {
    width: 240px;
    margin-left: -1.5rem;
    align-items: center;
  }

  .secondary-image h2 {
    margin-left: 0.2rem;
  }
  .secondary-image p {
    width: 270px;
    margin-left: 1rem;
    align-items: center;
  }
}
