/* .service__container {
  padding: 3rem 25.3rem 16rem;
  font-family: "AgencyR", sans-serif;
  flex-direction: column;
  align-items: center;
  margin-right: 20rem;
} */

/* body {
  overflow-x: hidden;
} */
.service__container {
  padding: 3rem 0 16rem;
  font-family: "AgencyR", sans-serif;
}

.service__list {
  display: flex;
  justify-content: center;
  column-gap: 10rem;
}

.service__img {
  width: 80px;
  margin-bottom: 0.5rem;
}

.service__item {
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--title-color);
  font-family: "Corbel", sans-serif;
  font-size: var(--h3-font-size);
  font-weight: 600;

  transition: transform 0.3s, background-color 0.1s;
}
.service__item:hover {
  border: none;
  transform: scale(1.05);
}

.service__item img {
  margin-bottom: 1rem;
}

/*-------------------------------------------BREAKPOINTS-----------------------------------------*/

/*For large devices*/

@media screen and (max-width: 1380px) {
  .service__container {
    padding-top: 2rem;
    padding: 3rem 0 6rem;
  }
}
@media screen and (max-width: 992px) {
  .section__title {
    font-size: 1.6rem;
  }

  .service__container {
    padding-top: 18rem;
  }
  .service__list {
    padding-left: 0.2rem;
    column-gap: 3rem;
  }
  .service__img {
    width: 40px;
  }
  .service__item {
    font-size: var(--normal-font-size);
  }
}
/* Estilos para telas grandes */

@media screen and (max-width: 768px) {
  .service__container {
    padding-top: 19rem;
  }
  .service__list {
    column-gap: 6rem;
  }
}
@media screen and (max-width: 576px) {
  .service__container {
    padding-top: 26rem;
    padding-right: 1rem;
  }

  .service__list {
    column-gap: 2rem;
    margin-right: 1.5rem;
  }
  .service__img {
    width: 40px;
  }
}

@media screen and (max-width: 430px) {
  .service__container {
  }
  .service__list {
    column-gap: 2rem;
    padding-left: 1.6rem;
  }
  .service__img {
    width: 40px;
  }
}
@media screen and (max-width: 414px) {
  .service__container {
  }
  .service__list {
    column-gap: 2rem;
    padding-left: 1.4rem;
  }
}

@media screen and (max-width: 390px) {
  .service__container {
  }
  .service__list {
    column-gap: 2rem;
    padding-left: 1.625rem;
  }
}

@media screen and (max-width: 375px) {
  .service__container {
    padding-right: 1rem;
  }
  .service__list {
    padding-left: 1.4rem;
    column-gap: 1rem;
  }
  .service__img {
    width: 30px;
  }
}

@media screen and (max-width: 350px) {
  .service__container {
    padding-top: 27rem;
  }
  .section__title {
    font-size: 1.4rem;
  }
  .service__item {
    font-size: var(--small-font-size);
  }
}

/* Estilos para telas médias */
